<template>
    <div class="p-2">

        <div class="d-flex align-items-center mb-2">
            <b-breadcrumb class="mb-0 flex-fill" :items="breadcrumbs"></b-breadcrumb>
            <div class="flex-wrap">

            </div>
        </div>
        <div class="px-4 py-2">
            <div class="row">
                <div class="col">
                    <h4>Account details</h4>
                    <surround-loader :is-loading="isLoading">
                        <b-card>
                            <b-form-group
                                    label-size="sm"
                                    label="Name:">
                                <b-form-input size="sm" v-model="formData.name"
                                              placeholder="Organisation name"></b-form-input>
                                <error-display v-model="errors" ident="name"></error-display>
                            </b-form-group>
                            <div class="row ">
                                <div class="col-4">
                                    <b-form-group
                                            label-size="sm"
                                            label="Address line 1:">
                                        <b-form-input size="sm" v-model="formData.address_line_1"></b-form-input>
                                        <error-display v-model="errors" ident="address_line_1"></error-display>
                                    </b-form-group>
                                </div>
                                <div class="col-4">
                                    <b-form-group
                                            label-size="sm"
                                            label="Address line 2:">
                                        <b-form-input size="sm" v-model="formData.address_line_2"></b-form-input>
                                        <error-display v-model="errors" ident="address_line_2"></error-display>
                                    </b-form-group>
                                </div>
                                <div class="col-4">
                                    <b-form-group
                                            label-size="sm"
                                            label="Postcode:">
                                        <b-form-input size="sm" v-model="formData.post_code"></b-form-input>
                                        <error-display v-model="errors" ident="post_code"></error-display>
                                    </b-form-group>
                                </div>
                                <div class="col-6">
                                    <b-form-group
                                            label-size="sm"
                                            label="Key contact email address: *">
                                        <b-form-input size="sm"
                                                      v-model="formData.notification_email"></b-form-input>
                                        <error-display v-model="errors" ident="notification_email"></error-display>
                                    </b-form-group>
                                </div>
                                <div class="col-6">
                                    <b-form-group
                                            label-size="sm"
                                            label="Key contact telephone number:">
                                        <b-form-input size="sm" v-model="formData.phone_number"></b-form-input>
                                        <error-display v-model="errors" ident="phone_number"></error-display>
                                    </b-form-group>
                                </div>
                            </div>


                            <div class="row ">
                                <div class="col-6">
                                    <b-form-group
                                            label-size="sm"
                                            label="Accounts payable contact name: *">
                                        <b-form-input size="sm" v-model="formData.accounts_name"></b-form-input>
                                        <error-display v-model="errors" ident="accounts_name"></error-display>
                                    </b-form-group>
                                </div>
                                <div class="col-6">
                                    <b-form-group
                                            label-size="sm"
                                            label="Accounts payable email address: *">
                                        <b-form-input size="sm" v-model="formData.accounts_email"></b-form-input>
                                        <error-display v-model="errors" ident="accounts_email"></error-display>
                                    </b-form-group>
                                </div>
                            </div>

                            <b-form-group class="mb-0 mt-3 text-right">
                                <b-button @click="saveModel" variant="success">Save</b-button>
                            </b-form-group>

                        </b-card>
                    </surround-loader>
                </div>
                <div v-if="!$store.getters.isWorkspaceAccount" class="col-4">
                    <h4>Payment methods</h4>
                    <b-card>
                        <stripe-card-collection></stripe-card-collection>
                    </b-card>
                </div>
            </div>
        </div>

    </div>
</template>

<script>

    import _ from 'lodash'
    import {organisationCurrent, organisationSetupCompanyDetails, paymentMethods} from "../../../modules/api/endpoints";
    import {getResource, saveResource} from "../../../modules/api/methods";
    import ErrorDisplay from "../../../components/form_inputs/ErrorDisplay";
    import StripeCardCollection from "../../../components/components/StripeCardCollection";
    import {containsErrors} from "../../../modules/helpers/helpers";
    import SurroundLoader from "../../../components/SurroundLoader";


    export default {
        name: "billing",
        components: {SurroundLoader, StripeCardCollection, ErrorDisplay},
        data: function () {
            return {
                isLoading: false,
                showModal: false,
                cards: [],
                stripe: null,
                errors: {},
                card: null,
                formData: {},
                loadingCardForm: false,
                breadcrumbs: [
                    {
                        text: 'Dashboard',
                        to: {'name': 'dashboard'}
                    },
                    {
                        text: 'Billing details',
                        active: true
                    }
                ]
            }
        },
        created() {
            this.loadModel();
        },
        methods: {
            loadModel() {
                this.isLoading = true;
                getResource(organisationCurrent).then((resp) => {
                    var data = resp.data.success.data;
                    this.formData = data;
                    this.isLoading = false;
                }).catch((ex) => {
                    console.log(ex);
                    this.isLoading = false;
                });
            },
            saveModel() {
                var vm = this;
                vm.errors = {};
                vm.isLoading = true;
                saveResource(organisationSetupCompanyDetails, vm.formData).then((resp) => {
                    console.log(resp);
                    this.$bvToast.toast('Account details updated', {
                        title: 'Success',
                        variant: 'success',
                        solid: false
                    })
                }).catch((err) => {
                    console.log(err);
                    if (containsErrors(err)) {
                        vm.errors = err.data.errors;
                    }
                }).finally(() => {
                    vm.isLoading = false;
                });
            }
        }


    }
</script>
